<template>
  <div class="card rezervasyon-form">
    <div class="card-body">
      <form-wizard color="#4466f2" :start-index="mutateTab" back-button-text="Geri Dön" next-button-text="İleri"
        :finish-button-text="finishButtonText" @on-complete="onComplete()" @on-validate="mergePartialModels"
        @on-change="wizardTabChange" ref="rezervasyonWizard" scope="props">
        <div slot="title">
          <div class="pull-right">
            <b-button @click="cikis()" variant="dark"><i class="fa fa-times"></i></b-button>
          </div>
        </div>
        <tab-content title="Turlar" :before-change="() => validateStep('tur')" @on-validate="mergePartialModels">
          <div class="setup-content" id="step-1">
            <b-alert show variant="danger" class="alert dark" v-if="turValidMessage != ''">
              <p v-html="turValidMessage"></p>
            </b-alert>
            <div class="row mega-inline">
              <div class="col-md-12">

                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr v-for="tur in turlar" :key="tur.Id">
                        <td width="5%">
                          <div class="m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox v-model="tur.Secili" :value="true"></b-form-checkbox>
                            </div>
                          </div>
                        </td>
                        <td width="10%">
                          {{ tur.Adi }}
                        </td>
                        <td>
                          <b-input-group>
                            <b-form-select :disabled="!tur.Secili" @change="opsiyonSec(tur)" v-model="tur.OpsiyonId"
                              :options="tur.Opsiyonlar" value-field="EncId" text-field="Adi">
                              <template #first>
                                <b-form-select-option :value="null">Opsiyon Seçiniz</b-form-select-option>
                              </template>
                            </b-form-select>
                            <b-input-group-append>
                              <b-button :disabled="tur.OpsiyonId == null" @click="opsiyonDetayAc(tur)" size="sm"
                                text="Button" variant="info"><i class="fa fa-info-circle"></i></b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </td>
                        <td>
                          <b-input-group>
                            <b-form-select :disabled="!tur.Secili" v-model="tur.RestoranId">
                              <option :value="0">Restoran Seçiniz</option>
                              <option v-for="option in tur.Restoranlar" :value="option.Id">
                                {{ option.Adi }} / {{ option.MenuFiyatiSatis }}₺
                              </option>
                            </b-form-select>
                            <b-input-group-append>
                              <b-button :disabled="tur.RestoranId == 0" @click="restoranDetayAc(tur)" size="sm"
                                text="Button" variant="info"><i class="fa fa-info-circle"></i></b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </td>
                        <td width="20%">
                          <datepicker :disabled="!tur.Secili" :language="tr"
                            input-class="datepicker-here form-control digits" id="kayit_tarih" v-model="tur.Tarih"
                            placeholder="Tur Tarihi" :format="date_format">
                          </datepicker>
                        </td>
                        <td width="10%">
                          <b-form-input :disabled="!tur.Secili" type="time" v-model="tur.Saat"></b-form-input>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <b-modal id="opsiyonDetay" size="lg" centered :title="opsiyonDetay.Adi" ok-title="Tamam"
                  cancel-title="Kapat" class="theme-modal">
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <p v-html="opsiyonDetay.Aciklama"></p>
                    </div>
                  </div>
                </b-modal>

                <b-modal id="restoranDetay" size="lg" centered :title="restoranDetay.Adi" ok-title="Tamam"
                  cancel-title="Kapat" class="theme-modal">
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <p v-html="restoranDetay.Aciklama"></p>
                    </div>
                  </div>
                </b-modal>

              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Detay" :before-change="() => validateStep('detay')">
          <div class="setup-content" id="step-2">
            <b-alert show variant="danger" class="alert dark" v-if="detayValidMessage != ''">
              <p>{{ detayValidMessage }}</p>
            </b-alert>

            <div class="row">
              <div class="col-md-12">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_aciklama">Açıklama</label>
                    <b-form-textarea id="kayit_aciklama" v-model="kayit.Aciklama"
                      placeholder="Açıklama"></b-form-textarea>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_oteladi">Otel Adı</label>
                    <b-form-input type="text" id="kayit_oteladi" v-model="kayit.OtelAdi" :state="kayit_oteladi_state"
                      placeholder="Otel Adı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_oteladi_state">Otel adını giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_dilid">Dil</label>
                    <b-form-select id="kayit_dilid" v-model="kayit.DilId" :options="kayit_dil_options"
                      :state="kayit_dilid_state"></b-form-select>
                    <b-form-invalid-feedback :state="kayit_dilid_state">Turun dilini seçiniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_oteladres">Otel Adresi</label>
                    <b-form-input type="text" id="kayit_oteladres" v-model="kayit.OtelAdresi"
                      placeholder="Otel Adresi"></b-form-input>
                  </div>
                </div>
                <hr />
                <div class="form-row">
                  <div class="col-md-3 mb-5 b-primary b-r-3 p-2">
                    <h4 class="f-w-600">Misafirler Ekle</h4>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_pasaport">Pasaport No / T.C. Kimlik No</label>
                        <b-form-input type="text" id="kayit_yolcu_pasaport" v-model="misafirKayit.PasaportNo"
                          placeholder="Pasaport No / T.C. Kimlik No"
                          :state="misafirekle_pasaportno_state"></b-form-input>
                        <b-form-invalid-feedback :state="misafirekle_pasaportno_state">Pasaport veya T.C. kimlik
                          numarasını
                          giriniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_adsoyad">Adı Soyadı</label>
                        <b-form-input type="text" id="kayit_yolcu_adsoyad" v-model="misafirKayit.AdSoyad"
                          placeholder="Adı Soyadı" :state="misafirekle_adsoyad_state"></b-form-input>
                        <b-form-invalid-feedback :state="misafirekle_adsoyad_state">Misafirin adını ve soyadını
                          giriniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_uyruk">Uyruk</label>
                        <multiselect id="kayit_yolcu_uyruk" v-model="misafirKayit.UyrukSelect"
                          tag-placeholder="Uyruk Seçiniz" placeholder="Uyruk seçiniz" label="text" selectLabel=""
                          deselectLabel="" selectedLabel="" track-by="value" :options="ulkeler"></multiselect>
                        <b-form-invalid-feedback :state="misafirekle_uyruk_state">Misafirin uyruğunu
                          seçiniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_yas">Yaş</label>
                        <b-form-select id="kayit_yolcu_yas" v-model="misafirKayit.Yas" :options="yas_options"
                          :state="misafirekle_yas_state"></b-form-select>
                        <b-form-invalid-feedback :state="misafirekle_yas_state">Misafirin yaş durumunu
                          seçiniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_telefon">Telefon</label>
                        <b-form-input type="text" id="kayit_yolcu_telefon" v-model="misafirKayit.Telefon"
                          placeholder="Telefon"></b-form-input>
                      </div>
                    </div>
                    <b-button type="button" @click="misafirEkle()" variant="info">Ekle</b-button>
                  </div>
                  <div class="col-md-9 mb-5">
                    <h4 class="f-w-600">
                      Misafirler
                      <b-badge variant="primary">{{ kayit.Misafirler.length }} kişi</b-badge>
                    </h4>

                    <div class="alert alert-info" v-if="kayit.Misafirler.length === 0">
                      Bu Tur İçin Misafir Girilmemiş
                    </div>

                    <div class="form-row">
                      <div class="col-md-4" v-for="row in kayit.Misafirler" v-bind:key="row.Id">
                        <div class="mb-2 b-dark b-r-3 p-2">
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_pasaport">Pasaport No / T.C. Kimlik No</label>
                              <b-form-input type="text" v-model="row.PasaportNo"
                                placeholder="Pasaport No / T.C. Kimlik No"></b-form-input>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_adsoyad">Adı Soyadı</label>
                              <b-form-input type="text" v-model="row.AdSoyad" placeholder="Adı Soyadı"></b-form-input>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_uyruk">Uyruk</label>
                              <multiselect v-model="row.UyrukSelect" tag-placeholder="Uyruk Seçiniz"
                                placeholder="Uyruk seçiniz" :id="row.Id" label="text" track-by="value"
                                :options="ulkeler" selectLabel="" deselectLabel="" selectedLabel="">
                              </multiselect>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_yas">Yaş</label>
                              <b-form-select v-model="row.Yas" :options="yas_options"></b-form-select>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_telefon">Telefon</label>
                              <b-form-input type="text" v-model="row.Telefon" placeholder="Telefon"></b-form-input>
                            </div>
                          </div>
                          <b-button type="button" @click="misafirKaldir(row)" variant="danger">Kaldır</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Rehber" :before-change="() => validateStep('rehber')">
          <div class="setup-content" id="step-3">
            <b-alert show variant="danger" class="alert dark" v-if="rehberValidMessage != ''">
              <p>{{ rehberValidMessage }}</p>
            </b-alert>

            <div class="row">
              <div class="col-md-4">
                <b-input-group prepend="Arama">
                  <b-form-input type="text" v-model="rehberFiltre.Kelime"
                    placeholder="Rehber adını girebilirsiniz"></b-form-input>
                  <b-input-group-append>
                    <b-button @click="rehberSayisiBul()" variant="outline-success"><i
                        class="fa fa-search"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-md-8">
                <b-pagination v-model="rehberSayfa" align="center" :total-rows="toplamRehberSayisi"
                  :per-page="sayfalamaSayfaBasiKayit"></b-pagination>
              </div>
            </div>

            <b-alert :show="rehberler === null" variant="info" class="alert dark">
              <p>Rehberler Yükleniyor.</p>
            </b-alert>
            <b-alert :show="rehberFiltre.Kelime === null && rehberler !== null && rehberler.length === 0"
              variant="warning" class="alert dark">
              <p>Şu an uygun rehber maalesef bulunmamaktadır.</p>
            </b-alert>
            <b-alert :show="rehberFiltre.Kelime !== null && rehberler.length === 0" variant="warning"
              class="alert dark">
              <p>Aradığınız kelime ile geçen bir rehber bulunamadı.</p>
            </b-alert>

            <div class="row" v-if="kayit.AracId === null || kayit.AracId > 0">
              <div class="col-md-6 mt-3 offset-md-3">
                <b-button variant="warning" @click="rehberSec(null)" block>
                  <strong style="color: #000"><i class="fa fa-flag"></i> REHBER SEÇMEDEN DEVAM ETMEK İSTİYORUM</strong>
                </b-button>
              </div>
            </div>

            <div class="row tur-rez-form-rehber mt-4">
              <div class="col-md-2 col-md-2-special" :if="rehberler !== null" v-for="rehber in rehberler"
                :key="rehber.Id">
                <b-card header-tag="div" header-class="p-3"
                  :bg-variant="rehber.Id === kayit.RehberId ? 'dark' : 'light'"
                  :header-bg-variant="rehber.Id === kayit.RehberId ? 'primary' : 'light'" body-class="p-1">
                  <h5 slot="header">
                    <b-link :class="rehber.Id === kayit.RehberId ? 'text-white' : 'text-dark'"
                      @click="rehberSec(rehber)">
                      {{ rehber.AdSoyad }}
                    </b-link>
                  </h5>
                  <div class="product-box">
                    <div class="product-img">
                      <img class="img-fluid" :src="rehber.Resim" alt />
                    </div>
                    <div class="product-details" :class="rehber.Id === kayit.RehberId ? 'text-white' : 'text-dark'">
                      <p class="rehber-fiyat bg-dark b-r-5"><c-money-cell :fiyat="rehber.SatisFiyati"
                          simge="₺"></c-money-cell>
                      </p>
                      <p><strong>{{ rehber.Dilleri }}</strong></p>
                      <b-button variant="warning" block @click="rehberDetayAc(rehber)">
                        Özgeçmiş
                      </b-button>
                      <b-button variant="primary" block :disabled="rehber.Id === kayit.RehberId"
                        @click="rehberSec(rehber)">
                        Rehberi Seç
                      </b-button>
                      <div class="yildiz-container" v-if="rehber.ToplamYorumSayisi > 0">
                        <div class="rating-container digits">
                          <star-rating v-model="rehber.Yildiz" :star-size="20" :max-rating="5" :show-rating="false"
                            :read-only="true"></star-rating>
                        </div>
                        <div class="yildiz-rakam">
                          <a href="javascript:" @click="rehberYorumAc(rehber)">({{ rehber.ToplamYorumSayisi }})</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>

            <b-pagination v-model="rehberSayfa" align="center" :total-rows="toplamRehberSayisi"
              :per-page="sayfalamaSayfaBasiKayit"></b-pagination>

            <b-modal id="rehberDetay" size="lg" hide-footer centered :title="rehberDetay.AdSoyad" cancel-title="Kapat"
              class="theme-modal">
              <div class="row">
                <div class="col-md-12">
                  <span v-html="rehberDetay.Ozgecmis"></span>

                  <b-button class="mt-3" block @click="$bvModal.hide('rehberDetay')">Kapat</b-button>
                </div>
              </div>
            </b-modal>

            <b-modal id="rehberYorumDetay" size="xl" centered title="Rehber Yorumları" hide-footer class="theme-modal">
              <RehberYorumlari :id="seciliRehberId" v-if="seciliRehberId != null">
              </RehberYorumlari>
            </b-modal>
          </div>
        </tab-content>
        <tab-content title="Araç" :before-change="() => validateStep('arac')">
          <div class="setup-content" id="step-4">
            <b-alert show variant="danger" class="alert dark" v-if="aracValidMessage != ''">
              <p>{{ aracValidMessage }}</p>
            </b-alert>

            <div class="row">
              <div class="col-md-4">
                <b-input-group prepend="Arama">
                  <b-form-input type="text" v-model="aracFiltre.Kelime"
                    placeholder="Şoför adını girebilirsiniz"></b-form-input>
                  <b-input-group-append>
                    <b-button @click="aracSayisiBul()" variant="outline-success"><i class="fa fa-search"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-md-8">
                <b-pagination v-model="aracSayfa" align="center" :total-rows="toplamAracSayisi"
                  :per-page="sayfalamaSayfaBasiKayit"></b-pagination>
              </div>
            </div>

            <b-alert :show="araclar === null" variant="info" class="alert dark">
              <p>Araçlar Yükleniyor.</p>
            </b-alert>
            <b-alert :show="aracFiltre.Kelime === null && araclar !== null && araclar.length === 0" variant="warning"
              class="alert dark">
              <p>Şu an uygun araç maalesef bulunmamaktadır.</p>
            </b-alert>
            <b-alert :show="aracFiltre.Kelime !== null && araclar.length === 0" variant="warning" class="alert dark">
              <p>Aradığınız kelime ile geçen bir rehber bulunamadı.</p>
            </b-alert>

            <div class="row" v-if="kayit.RehberId > 0">
              <div class="col-md-6 mt-3 offset-md-3">
                <b-button variant="warning" @click="aracSec(null)" block>
                  <strong style="color: #000"><i class="fa fa-car"></i> ARAÇ SEÇMEDEN DEVAM ETMEK İSTİYORUM</strong>
                </b-button>
              </div>
            </div>

            <div class="row tur-rez-form-arac mt-4">
              <div class="col-md-2 col-md-2-special" :if="araclar !== null" v-for="arac in araclar" :key="arac.Id">
                <b-card header-tag="div" header-class="p-3" :bg-variant="arac.Id === kayit.AracId ? 'dark' : 'light'"
                  :header-bg-variant="arac.Id === kayit.AracId ? 'primary' : 'light'" body-class="p-1">
                  <h5 slot="header">
                    <b-link :class="arac.Id === kayit.AracId ? 'text-white' : 'text-dark'" @click="aracSec(arac)">
                      {{ arac.SoforAdi }}
                    </b-link>
                    <div class="pull-right">
                      <b-badge v-if="arac.isWifi" variant="warning">
                        <i class="fa fa-wifi" aria-hidden="true" style="font-size: 14px"></i>
                      </b-badge>
                      <b-badge v-if="arac.isAracTakip" variant="warning">
                        <i class="fa fa-location-arrow" aria-hidden="true" style="font-size: 14px"></i>
                      </b-badge>
                    </div>
                  </h5>
                  <div class="product-box">
                    <div class="product-img">
                      <b-carousel :id="'carousel-' + arac.Id" :interval="4000" controls indicators background="#ababab"
                        img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333">
                        <b-carousel-slide v-for="(resim, index) in arac.Resimler" :key="index"
                          :img-src="resim"></b-carousel-slide>
                      </b-carousel>
                    </div>
                    <div class="product-details">
                      <b-button class="primary" :disabled="arac.Id === kayit.AracId" @click="aracSec(arac)">
                        Aracı Seç
                      </b-button>
                      <div class="yildiz-container" v-if="arac.ToplamYorumSayisi > 0">
                        <div class="rating-container digits">
                          <star-rating v-model="arac.Yildiz" :star-size="20" :max-rating="5" :show-rating="false"
                            :read-only="true"></star-rating>
                        </div>
                        <div class="yildiz-rakam">
                          <a href="javascript:" @click="aracYorumAc(arac)">({{ arac.ToplamYorumSayisi }})</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>

            <b-pagination v-model="aracSayfa" align="center" :total-rows="toplamAracSayisi"
              :per-page="sayfalamaSayfaBasiKayit"></b-pagination>

            <b-modal id="aracYorumDetay" size="xl" centered title="Araç Yorumları" hide-footer class="theme-modal">
              <AracYorumlari :id="seciliAracId" v-if="seciliAracId != null">
              </AracYorumlari>
            </b-modal>
          </div>
        </tab-content>
        <tab-content title="Önizleme" :before-change="() => validateStep('onizleme')">
          <div class="setup-content" id="step-5" v-if="turHesapla.length > 0">
            <div class="row">
              <div class="col-md-3">
                <b-card header-tag="div">
                  <h5 slot="header">
                    {{ activeRehber.AdSoyad }}
                  </h5>
                  <div class="product-box">
                    <div class="product-img">
                      <img class="img-fluid" :src="activeRehber.Resim" alt />
                    </div>
                  </div>
                </b-card>
              </div>
              <div class="col-md-3">
                <b-card header-tag="div">
                  <h5 slot="header">
                    {{ activeArac.SoforAdi }}
                    <div class="pull-right">
                      <b-badge v-if="activeArac.isWifi" variant="warning">
                        <i class="fa fa-wifi" aria-hidden="true" style="font-size: 14px"></i>
                      </b-badge>
                      <b-badge v-if="activeArac.isAracTakip" variant="warning">
                        <i class="fa fa-location-arrow" aria-hidden="true" style="font-size: 14px"></i>
                      </b-badge>
                    </div>
                  </h5>
                  <div class="product-box">
                    <div class="product-img">
                      <img class="img-fluid" v-if="activeArac.Resimler != null &&
        activeArac.Resimler.length > 0
        " :src="activeArac.Resimler[0]" alt />
                    </div>
                  </div>
                </b-card>
              </div>
            </div>

            <b-card header-tag="header" header-bg-variant="primary" v-for="detay in turHesapla" :key="detay.Tur.Id">
              <template #header>
                <h6 class="mb-0">{{ detay.Tur.Adi }} / {{ $moment(String(detay.Tur.Tarih)).format('DD.MM.YYYY') }} {{
        detay.Tur.Saat }}</h6>
              </template>

              <div class="row">
                <div class="col-md-6">
                  <h5>{{ detay.Tur.Opsiyon.Adi }}</h5>
                  <h4 v-if="detay.Tur.Opsiyon.Fiyat > 0">
                    {{ detay.Tur.Opsiyon.Fiyat }} ₺
                  </h4>
                  <p v-if="activeArac.Id == 0"><strong>BU OPSİYON: Sadece Profesyonel Rehberlik hizmetini kapsamaktadır.</strong></p>
                  <p v-if="activeRehber.Id == 0"><strong>BU OPSİYON: Sadece Özel Araç kiralama hizmetini kapsamaktadır.</strong></p>
                  <p v-html="detay.Tur.Opsiyon.Aciklama"></p>
                  <h5>Otel : <strong>{{ kayit.OtelAdi }} <small>{{ kayit.OtelAdresi }}</small></strong></h5>
                  <h5>Tarih / Saat: {{ $moment(String(detay.Tur.Tarih)).format('DD.MM.YYYY') }} {{ detay.Tur.Saat }}
                  </h5>

                  <table class="product-page-width mt-5">
                    <tbody>
                      <tr>
                        <th>Toplam Tutar</th>
                        <td>
                          <b-badge variant="success"><c-money-cell :fiyat="detay.Hesap.ToplamTutar"
                              simge="₺"></c-money-cell></b-badge>
                        </td>
                      </tr>
                      <tr v-if="detay.TurTutar > 0">
                        <th>Tur Toplamı</th>
                        <td>
                          <b-badge variant="warning"><c-money-cell :fiyat="detay.Hesap.TurTutar"
                              simge="₺"></c-money-cell></b-badge>
                        </td>
                      </tr>
                      <tr v-if=detay.Hesap.RestoranTutar>0>
                        <th>Restoran Tutar</th>
                        <td>
                          <b-badge variant="secondary"><c-money-cell :fiyat="detay.Hesap.RestoranTutar"
                              simge="₺"></c-money-cell></b-badge>
                        </td>
                      </tr>
                      <tr v-if="detay.Hesap.MuzeTutar > 0">
                        <th>Müze Tutar</th>
                        <td>
                          <b-badge variant="info"><c-money-cell :fiyat="detay.Hesap.MuzeTutar"
                              simge="₺"></c-money-cell></b-badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6">
                  <h5>Tur Detayları</h5>

                  <b-card header-tag="div" header-bg-variant="success">
                    <h5 slot="header">Mağazalar</h5>
                    <div class="alert alert-info">
                      <feather type="alert-circle"></feather> Seçili mağazalarınız aşağıda listelenmektedir.<br>Mağaza
                      değiştirmek
                      isterseniz aşağıdan yapabilirsiniz.
                    </div>
                    <div class="col-md-12">
                      <b-list-group v-if="detay.Tur.Mekanlar.length > 0">
                        <b-list-group-item v-for="mekan in detay.Tur.Mekanlar" :key="mekan.Id" tag="a" href="#"
                          class="flex-column align-items-start" @click="ekliMekanlarCikar(detay.Tur, mekan)">
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              <i class="fa fa-minus-circle"></i>
                              {{ mekan.Adi }}
                            </h5>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                      <hr>
                      <b-form-select class="mb-3" style="border: 1px solid #333;" v-model="mekanKategori"
                        @change="mekanListele()" :options="mekankategori_options"></b-form-select>
                      <b-list-group v-if="mekanlar.length > 0">
                        <b-list-group-item v-for="mekan in mekanlar" :key="mekan.Id" tag="a" href="#"
                          class="flex-column align-items-start" @click="ekliMekanlarEkle(detay.Tur, mekan)">
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              <i class="fa fa-plus-square-o"></i>
                              {{ mekan.Adi }}
                            </h5>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </b-card>

                  <b-card header-tag="div" header-bg-variant="success" v-if="detay.Hesap.Detay.Restoran !== null">
                    <h5 slot="header">Restoran</h5>
                    <b-list-group v-if="detay.Hesap.Detay.Restoran.RestoranAdi !== ''">
                      <b-list-group-item tag="a" href="#" class="flex-column align-items-start">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">
                            {{ detay.Hesap.Detay.Restoran.RestoranAdi }}
                          </h5>
                        </div>
                        <p class="mb-1" v-html="detay.Hesap.Detay.Restoran.RestoranAciklama"></p>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>

                  <b-card header-tag="div" header-bg-variant="success" v-if="detay.Hesap.Detay.Muzeler.length > 0">
                    <h5 slot="header">Müzeler</h5>
                    <b-list-group>
                      <b-list-group-item v-for="muze in detay.Hesap.Detay.Muzeler" :key="muze.Id" tag="a" href="#"
                        class="flex-column align-items-start">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">{{ muze.MuzeAdi }}</h5>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <b-card header-tag="div" v-if="detay.Hesap.TurTutar > 0" header-bg-variant="primary" body-class="p-3">
                    <h5 slot="header">Tur Opsiyon Tutarları</h5>
                    <b-table striped hover :fields="opsiyonTutarFields" :items="detay.Hesap.Detay.OpsiyonTutarlar">
                      <template #cell(YasStr)="row">
                        <b-badge variant="primary">{{ row.item.YasStr }}</b-badge>
                      </template>
                      <template #cell(Oran)="data">
                        <b-badge variant="primary">{{ data.value }}</b-badge>
                      </template>
                      <template #cell(Fiyat)="data">
                        <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="TL"></c-money-cell>
                      </template>
                      <template #cell(ToplamFiyat)="data">
                        <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                      </template>
                      <template #cell(AcenteFiyati)="data">
                        <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                      </template>
                    </b-table>
                  </b-card>

                  <b-card header-tag="div" v-if="detay.Hesap.Detay.Muzeler.length > 0" header-bg-variant="primary"
                    body-class="p-3">
                    <h5 slot="header">Müzeler</h5>
                    <b-card class="mb-1" v-for="muze in detay.Hesap.Detay.Muzeler" v-bind:key="muze.MuzeId"
                      body-class="p-0">
                      <b-card-header header-tag="div" class="bg-primary">
                        <h5 class="mb-0">
                          {{ muze.MuzeAdi }}
                          <small class="digits">Fiyat {{ muze.MuzeFiyatiTL }}₺</small>
                          /
                          <small class="digits">Toplam {{ muze.ToplamTutarTL }}₺</small>
                        </h5>
                      </b-card-header>
                      <b-table striped hover :fields="muzeTutarFields" :items="muze.Tutarlar"></b-table>
                    </b-card>
                  </b-card>

                  <b-card header-tag="div" header-bg-variant="primary" body-class="p-3"
                    v-if="detay.Hesap.Detay.Restoran != null">
                    <h5 slot="header">Restoran</h5>
                    <b-card class="mb-1" body-class="p-0">
                      <b-card-header header-tag="div" class="bg-primary">
                        <h5 class="mb-0">
                          {{ detay.Hesap.Detay.Restoran.RestoranAdi }}
                        </h5>
                      </b-card-header>
                      <p>
                        <small class="digits">Menü Fiyatı :
                          {{
        $formatHelper.formatMoney(
          detay.Hesap.Detay.Restoran.MenuSatisFiyati
        )
      }}₺</small>
                        /
                        <small class="digits">Toplam :
                          {{
          $formatHelper.formatMoney(
            detay.Hesap.Detay.Restoran.ToplamSatis
          )
                          }}₺</small>
                      </p>
                      <b-table striped hover :fields="restoranTutarFields"
                        :items="detay.Hesap.Detay.Restoran.Tutarlar"></b-table>
                    </b-card>
                  </b-card>
                </div>
              </div>
            </b-card>


          </div>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import { BFormTimepicker } from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import StarRating from 'vue-star-rating';
import RehberYorumlari from './c_rehber_yorumlar_rez';
import AracYorumlari from './c_arac_yorumlar_rez';

export default {
  props: {
    acenteId: "",
    tab: 0,
    id: undefined,
    misafirler: null
  },
  data() {
    return {
      mutateTab: this.tab === undefined ? 0 : parseInt(this.tab),

      finishButtonText: "Oluştur",

      turValidMessage: "",
      detayValidMessage: "",
      rehberValidMessage: "",
      aracValidMessage: "",

      date_format: "dd.MM.yyyy",
      tr: tr,

      mutationAcenteId: this.acenteId,

      kayit: {
        Aciklama: null,
        OtelAdi: null,
        OtelAdresi: null,
        DilId: 0,
        EncDilId: null,
        RehberId: null,
        EncRehberId: null,
        AracId: null,
        EncAracId: null,
        KisiSayisi: 0,
        Misafirler: [],
        Turlar: []
      },

      opsiyonTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "Fiyat", label: "Fiyat", sortable: false },
        { key: "ToplamFiyat", label: "Toplam", sortable: false },
        { key: "AcenteFiyati", label: "Acente Ö.F.", sortable: false },
      ],

      muzeTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "FiyatTL", label: "Fiyat", sortable: false },
        { key: "ToplamTutarTL", label: "Toplam", sortable: false },
      ],

      restoranTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "SatisFiyat", label: "Menü Fiyatı", sortable: false },
        { key: "ToplamSatisFiyat", label: "Toplam", sortable: false },
      ],

      mekankategori_options: [
        { value: "0", text: "Mağaza Kategorileri" }
      ],
      mekanKategori: 0,
      mekanlar: [],

      ekliMekanlar: [],

      turHesapla: [],

      finalModel: {},

      activeRehber: {},
      activeArac: {},

      seciliRehberId: null,
      seciliAracId: null,

      turlar: [],
      opsiyonDetay: {},
      restoranDetay: {},

      yas_options: [],

      kayit_dil_options: [{ value: "0", text: "Seçiniz" }],

      ulkeler: [{ value: "", text: "Uyruk" }],

      misafirKayit: {
        Id: 0,
        PasaportNo: "",
        Uyruk: "",
        UyrukSelect: { value: "", text: "Uyruk" },
        AdSoyad: "",
        Yas: 2,
        Telefon: "",
      },

      sayfalamaSayfaBasiKayit: 12,

      rehberFiltre: {
        Kelime: null,
        DilId: null,
        TurTarihi: null,
        AcenteId: null,
        RehberId: 0,
        Skip: 0,
        Take: 0,
        TurTarihleri: ""
      },
      rehberler: null,
      toplamRehberSayisi: 0,
      rehberSayfa: 1,

      aracFiltre: {
        Kelime: null,
        TurTarihi: null,
        AcenteId: null,
        AracId: 0,
        Skip: 0,
        Take: 0,
        TurTarihleri: ""
      },
      araclar: null,
      toplamAracSayisi: 0,
      aracSayfa: 1,

      rehberDetay: {},
    };
  },
  mounted() {
    this.kayit.Tarih = this.addDays(1);
    this.finishButtonText = "Oluştur";

    if (this.acenteId === 0) {
      this.mutationAcenteId = this.$user.acenteId();
    }

    this.turList();

    if (this.mutateTab > 0) {
      this.wizardTabChange(this.mutateTab - 1, this.mutateTab);
    }

    this.onYasList();
    this.onDilList();
    this.ulkeList();
  },
  components: {
    Datepicker,
    BFormTimepicker,
    Multiselect,
    StarRating,
    RehberYorumlari,
    AracYorumlari
  },
  computed: {
    kayit_oteladi_state() {
      if (this.kayit.OtelAdi == "" || this.kayit.OtelAdi == null) return false;
      else return true;
    },
    kayit_dilid_state() {
      if (this.kayit.DilId == "" || this.kayit.DilId == null) return false;
      else return true;
    },
    kayit_misafir_state() {
      if (this.kayit.Misafirler == null || this.kayit.Misafirler.length == 0)
        return false;
      else return true;
    },
    misafirekle_pasaportno_state() {
      if (
        this.misafirKayit.PasaportNo == "" ||
        this.misafirKayit.PasaportNo == null
      )
        return false;
      else return true;
    },
    misafirekle_adsoyad_state() {
      if (this.misafirKayit.AdSoyad == "" || this.misafirKayit.AdSoyad == null)
        return false;
      else return true;
    },
    misafirekle_uyruk_state() {
      if (
        this.misafirKayit.UyrukSelect == null ||
        this.misafirKayit.UyrukSelect.value == ""
      )
        return false;
      else return true;
    },
    misafirekle_yas_state() {
      if (this.misafirKayit.Yas == "" || this.misafirKayit.Yas == null)
        return false;
      else return true;
    },
    kayit_rehberid_state() {
      if (this.kayit.RehberId === null)
        return false;
      else return true;
    },
    kayit_aracid_state() {
      if (this.kayit.AracId === null) return false;
      else return true;
    }
  },
  watch: {
    rehberSayfa: function () {
      this.onRehberlerList();
    },
    aracSayfa: function () {
      this.onAraclarList();
    },
    misafirler: function () {
      this.resetForm();
      this.kayit.Misafirler = structuredClone(this.misafirler);
    }
  },
  methods: {
    resetForm() {
      this.kayit = {
        TurId: null,
        EncTurId: null,
        TurOpsiyonId: null,
        EncTurOpsiyonId: null,
        Tarih: this.addDays(1),
        Saat: "09:00",
        Aciklama: null,
        OtelAdi: null,
        OtelAdresi: null,
        DilId: 0,
        EncDilId: null,
        RehberId: null,
        EncRehberId: null,
        AracId: null,
        EncAracId: null,
        KisiSayisi: 0,
        Misafirler: [],
        RestoranId: 0,
        Mekanlar: []
      };
      this.finishButtonText = "Oluştur";
      this.mutateTab = 0;
      this.turValidMessage = "";
      this.detayValidMessage = "";
      this.rehberValidMessage = "";
      this.aracValidMessage = "";
      this.ekliMekanlar = [];
      this.turHesapla = {};
      this.finalModel = {};
      this.activeTur = {};
      this.activeOpsiyon = {};
      this.activeOpsiyonDetay = {};
      this.activeRehber = {};
      this.activeArac = {};
      this.turOpsiyonlari = [];

      this.$refs.rezervasyonWizard.reset();
    },
    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep;
      }
      return false;
    },
    formatTarih(tarih) {
      return this.$moment(String(tarih)).format("YYYY-MM-DD");
    },
    validateStep(name) {
      switch (name) {
        case "tur":
          this.turValidMessage = "";
          for (let index = 0; index < this.turlar.length; index++) {
            const element = this.turlar[index];
            if (element.Secili) {
              if (element.OpsiyonId == null) {
                this.turValidMessage += element.Adi + ' turu için opsiyon seçilmedi.<br />';
              }
              if (element.Restoranlar.length > 0 && element.RestoranId == null) {
                this.turValidMessage += element.Adi + ' turu için restoran seçilmedi.<br />';
              }
              if (element.Tarih == null || element.Tarih == '') {
                this.turValidMessage += element.Adi + ' turu için tarih seçilmedi.<br />';
              }
              if (element.Saat == null || element.Saat == '') {
                this.turValidMessage += element.Adi + ' turu için saat seçilmedi.<br />';
              }
            }
          }
          if (this.turValidMessage != "")
            return false;
          break;

        case "detay":
          this.detayValidMessage = "";

          if (!this.kayit_oteladi_state) {
            this.detayValidMessage = "Otel adını giriniz.";
            return false;
          }
          if (!this.kayit_dilid_state) {
            this.detayValidMessage = "Dili seçiniz.";
            return false;
          }
          if (!this.kayit_misafir_state) {
            this.detayValidMessage = "En az bir misafir giriniz.";
            return false;
          }
          break;

        case "rehber":
          this.rehberValidMessage = "";
          if (!this.kayit_rehberid_state) {
            this.rehberValidMessage = "Rehber Seçiniz.";
            return false;
          }
          break;

        case "arac":
          this.aracValidMessage = "";
          if (!this.kayit_aracid_state) {
            this.aracValidMessage = "Araç Seçiniz.";
            return false;
          }
          break;

        default:
          break;
      }
      return true;
    },
    mergePartialModels(model, isValid) {
      return isValid;
    },
    wizardTabChange: function (prevIndex, nextIndex) {
      switch (nextIndex) {
        case 0:
          return true;
        case 2:
          this.rehberSayisiBul();
          break;
        case 3:
          this.aracSayisiBul();
          break;
        case 4:
          this.detayHesapla();
          this.kategoriList();
          break;

        default:
          break;
      }
      window.scrollTo({ top: 0, left: 0 });
    },
    addDays(days) {
      let date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    },
    turList() {
      let vm = this;
      this.$connection.get("/turlar/forSaleAll/0", function (response) {
        vm.turlar = response.map((item, index) => {
          return {
            ...item,
            Secili: false,
            Restoranlar: [],
            Opsiyon: {},
            OpsiyonId: null,
            RestoranId: 0,
            Tarih: vm.addDays(index + 1),
            Saat: '09:00',
            Mekanlar: []
          };
        });
      });
    },
    opsiyonDetayAc(tur) {
      let opsiyon = tur.Opsiyonlar.filter((item) => {
        return item.EncId == tur.OpsiyonId;
      });
      if (opsiyon.length > 0) {
        this.opsiyonDetay = {
          Adi: opsiyon[0].Adi,
          Aciklama: opsiyon[0].Aciklama
        };
        this.$bvModal.show('opsiyonDetay');
      }
    },
    opsiyonSec(tur) {
      let vm = this;
      tur.Restoranlar = [];
      tur.RestoranId = 0;
      let opsiyon = tur.Opsiyonlar.filter(opsiyon => opsiyon.EncId == tur.OpsiyonId);
      tur.Opsiyon = opsiyon.length > 0 ? opsiyon[0] : null;

      if (tur.OpsiyonId !== null) {
        this.$connection.get(
          "/turlar/opsiyonlarGetForSaleById/" + tur.OpsiyonId,
          function (response) {
            if (response.Success) {
              tur.Restoranlar = response.Data.Restoranlar;
            } else {
              vm.$alert.messageWithTitle("Tur Opsiyon Detay", response.Message, "error");
            }
          }
        );
      }
    },
    restoranDetayAc(tur) {
      let restoran = tur.Restoranlar.filter((item) => {
        return item.Id == tur.RestoranId;
      });
      if (restoran.length > 0) {
        this.restoranDetay = {
          Adi: restoran[0].Adi,
          Aciklama: restoran[0].Aciklama
        };
        this.$bvModal.show('restoranDetay');
      }
    },
    onYasList() {
      let vm = this;

      this.$connection.get("/parametreler/yaslar", function (response) {
        vm.yas_options = response;
      });
    },
    onDilList() {
      let vm = this;

      this.$connection.get("/diller/select", function (response) {
        vm.kayit_dil_options = vm.kayit_dil_options.concat(response);
      });
    },
    ulkeList() {
      let vm = this;
      this.$connection.get("/ulkeler/select", function (response) {
        vm.ulkeler = vm.ulkeler.concat(response);
      });
    },
    misafirEkle() {
      let isValid =
        this.misafirekle_pasaportno_state &&
        this.misafirekle_adsoyad_state &&
        this.misafirekle_uyruk_state &&
        this.misafirekle_yas_state;

      if (isValid) {
        this.misafirKayit.Uyruk = this.misafirKayit.UyrukSelect.value;
        this.misafirKayit.Id = this.kayit.Misafirler.length + 1;

        this.kayit.Misafirler.push(this.misafirKayit);

        this.misafirKayit = {
          Id: 0,
          PasaportNo: "",
          AdSoyad: "",
          Uyruk: this.misafirKayit.UyrukSelect.value,
          UyrukSelect: this.misafirKayit.UyrukSelect,
          Yas: this.misafirKayit.Yas,
          Telefon: this.misafirKayit.Telefon,
        };
      }
    },
    misafirKaldir(misafir) {
      this.$listHelper.removeItem(this.kayit.Misafirler, misafir);
    },
    rehberSayisiBul() {
      let dilId = this.kayit.DilId;
      let turTarihi = this.formatTarih(this.kayit.Tarih);

      let vm = this;

      vm.rehberFiltre.DilId = dilId;
      vm.rehberFiltre.TurTarihi = turTarihi;
      vm.rehberFiltre.AcenteId = vm.mutationAcenteId;
      vm.rehberFiltre.RehberId = 0;
      vm.rehberFiltre.Skip = 0;
      vm.rehberFiltre.Take = 0;

      let seciliTarihler = this.turlar.filter(tur => tur.Secili)
        .map(tur => vm.formatTarih(tur.Tarih));
      vm.rehberFiltre.TurTarihleri = seciliTarihler.join(',');

      if (this.activeRehber.Id !== undefined) {
        vm.rehberFiltre.RehberId = this.activeRehber.Id;
      } else if (this.rezervasyon !== undefined) {
        vm.rehberFiltre.RehberId = this.rezervasyon.RehberId;
      }

      this.$connection.post("/rehberler/selectCount", vm.rehberFiltre, 'post', function (count) {
        vm.toplamRehberSayisi = count;

        vm.onRehberlerList();
      });
    },
    onRehberlerList() {
      let vm = this;

      vm.rehberFiltre.Skip = ((this.rehberSayfa - 1) * this.sayfalamaSayfaBasiKayit);
      vm.rehberFiltre.Take = this.sayfalamaSayfaBasiKayit;

      if (this.activeRehber.Id !== undefined) {
        vm.rehberFiltre.RehberId = this.activeRehber.Id;
      } else if (this.rezervasyon !== undefined) {
        vm.rehberFiltre.RehberId = this.rezervasyon.RehberId;
      }

      vm.$connection.post("/rehberler/select", vm.rehberFiltre, 'post', function (response) {
        vm.rehberler = response;
        let rehbers = vm.rehberler.filter((item) => {
          return item.Id === vm.kayit.RehberId;
        });
        if (rehbers.length > 0) vm.activeRehber = rehbers[0];
      });
    },
    rehberSec(rehber) {
      if (rehber == null) {
        rehber = {
          Id: 0,
          EncId: "0",
          AdSoyad: "Rehbersiz",
          Resim: "",
          Dilleri: "",
          Ozgecmis: "",
          Yildiz: 0,
          ToplamYorumSayisi: 0
        }
      }

      this.kayit.RehberId = rehber.Id;
      this.kayit.EncRehberId = rehber.EncId;
      this.activeRehber = rehber;
      this.$refs.rezervasyonWizard.nextTab();
    },
    rehberDetayAc(rehber) {
      this.rehberDetay = rehber;
      this.$bvModal.show("rehberDetay");
    },
    rehberYorumAc(rehber) {
      this.seciliRehberId = rehber.EncId;
      this.$bvModal.show("rehberYorumDetay");
    },
    aracSayisiBul() {
      let turTarihi = this.formatTarih(this.kayit.Tarih);
      let vm = this;

      vm.aracFiltre.TurTarihi = turTarihi;
      vm.aracFiltre.AcenteId = vm.mutationAcenteId;
      vm.aracFiltre.AracId = 0;
      vm.aracFiltre.Skip = 0;
      vm.aracFiltre.Take = 0;

      let seciliTarihler = this.turlar.filter(tur => tur.Secili)
        .map(tur => vm.formatTarih(tur.Tarih));
      vm.aracFiltre.TurTarihleri = seciliTarihler.join(',');

      if (this.activeArac.Id !== undefined) {
        vm.aracFiltre.AracId = this.activeArac.Id;
      } else if (this.rezervasyon !== undefined) {
        vm.aracFiltre.AracId = this.rezervasyon.AracId;
      }

      this.$connection.post("/araclar/selectCount", vm.aracFiltre, 'post', function (count) {
        vm.toplamAracSayisi = count;
        if (vm.toplamAracSayisi > 0) {
          vm.onAraclarList();
        }
      });
    },
    onAraclarList() {
      let vm = this;

      vm.aracFiltre.Skip = ((this.aracSayfa - 1) * this.sayfalamaSayfaBasiKayit);
      vm.aracFiltre.Take = this.sayfalamaSayfaBasiKayit;

      if (this.activeArac.Id !== undefined) {
        vm.aracFiltre.AracId = this.activeArac.Id;
      } else if (this.rezervasyon !== undefined) {
        vm.aracFiltre.AracId = this.rezervasyon.AracId;
      }

      vm.$connection.post("/araclar/select", vm.aracFiltre, 'post', function (response) {
        vm.araclar = response;

        let aracs = vm.araclar.filter((item) => {
          return item.Id === vm.kayit.AracId;
        });
        if (aracs.length > 0) vm.activeArac = aracs[0];
      });
    },
    aracSec(arac) {
      if (arac == null) {
        arac = {
          Id: 0,
          EncId: "0",
          AracFirmaId: 0,
          EncAracFirmaId: null,
          SoforAdi: "Araçsız",
          Plaka: "Araçsız",
          Marka: "",
          Model: "",
          isWifi: false,
          isAracTakip: false,
          AracTipiTanim: null,
          Resimler: [],
          Yildiz: 0,
          ToplamYorumSayisi: 0
        }
      }

      this.kayit.AracId = arac.Id;
      this.kayit.EncAracId = arac.EncId;
      this.activeArac = arac;
      this.$refs.rezervasyonWizard.nextTab();
    },
    aracYorumAc(arac) {
      this.seciliAracId = arac.EncId;
      this.$bvModal.show("aracYorumDetay");
    },
    detayHesapla() {
      let vm = this;

      let url = "/turRezervasyonlari/hesapla/" + this.mutationAcenteId + "/";
      let method = "post";
      let messageTitle = "Rezervasyon Detayları Hesapla";

      let kayitClone = { ...this.kayit };

      let seciliTarihler = this.turlar.filter(tur => tur.Secili);

      seciliTarihler.sort((a, b) => a.Tarih - b.Tarih);
      
      for (let i = 0; i < kayitClone.Misafirler.length; i++) {
        const element = kayitClone.Misafirler[i];
        element.Uyruk = element.UyrukSelect.value;
      }

      kayitClone.RehberId = kayitClone.EncRehberId;
      kayitClone.AracId = kayitClone.EncAracId;
      kayitClone.KisiSayisi = kayitClone.Misafirler.length;

      this.turHesapla = [];

      for (let index = 0; index < seciliTarihler.length; index++) {
        const element = seciliTarihler[index];

        kayitClone.TurId = element.EncId;
        kayitClone.TurOpsiyonId = element.OpsiyonId;
        kayitClone.RestoranId = element.RestoranId;
        kayitClone.Tarih = this.$moment(String(element.Tarih)).format("YYYY-MM-DD");
        kayitClone.Saat = element.Saat;

        vm.$connection.post(
          url,
          kayitClone,
          method,
          function (response) {
            if (response.Success) {
              vm.turHesapla.push(
                {
                  Tur: element,
                  Hesap: response.Data
                }
              );

              element.Mekanlar = [];
              for (let index = 0; index < response.Data.Detay.Mekanlar.length; index++) {
                const mekanElement = response.Data.Detay.Mekanlar[index];
                element.Mekanlar.push({
                  Id: mekanElement.MekanId,
                  Adi: mekanElement.MekanAdi,
                  KategoriAdi: mekanElement.KategoriAdi
                })
              }
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
    basaDon() {
      this.mutateTab = 0;
    },
    cikis() {
      let vm = this;
      this.$alert.confirm(
        "İptal",
        "Rezervasyondan vazgeçiyorsunuz yapmış olduğunuz değişiklikler kaybolacaktır. Emin misiniz?",
        "error",
        function () {
          if (vm.id == undefined) {
            vm.$router.push("/");
          } else {
            vm.$router.push("/turRezervasyon/detay/" + vm.id);
          }
        }
      );
    },
    kategoriList() {
      let vm = this;

      this.$connection.get("/kategoriler/select/1", function (response) {
        let options = [{ value: "0", text: "Mağaza Kategorileri" }];
        vm.mekankategori_options = options.concat(response);
      });
    },
    mekanListele() {
      let vm = this;
      let filtre = {
        KategoriId: this.mekanKategori
      };
      this.$connection.post(
        "/mekanlar/selectFiltre",
        filtre,
        "post",
        function (response) {
          vm.mekanlar = response;
        }
      );
    },
    ekliMekanlarEkle(tur, mekan) {
      var filtre = tur.Mekanlar.filter(x => { return x.Id == mekan.Id });
      if (filtre.length === 0) {
        filtre = tur.Mekanlar.filter(x => { return x.KategoriAdi == mekan.KategoriAdi });
        if (filtre.length > 0) {
          this.$alert.messageWithTitle("Mağaza Ekle", "Aynı kategoriden sadece 1 tane mağaza ekleyebilirsiniz.", "danger");
          return;
        }
        tur.Mekanlar.push(mekan);
      }
      this.$alert.messageWithTitle("Mağaza Ekle", "Mağaza tur özelinde eklendi.", "success");
      this.mekanKategori = 0;
      this.mekanlar = [];
    },
    ekliMekanlarCikar(tur, mekan) {
      this.$listHelper.removeItem(tur.Mekanlar, mekan);
    },
    onComplete() {
      let isValid =
        this.kayit_oteladi_state &&
        this.kayit_dilid_state &&
        (this.kayit_rehberid_state && this.kayit_aracid_state);

      if (!isValid) {
        this.$alert.messageWithTitle(
          "Eksik Bilgi",
          "Lütfen rezervasyon bilgilerinizi kontrol ediniz.",
          "error"
        );
      } else {
        this.kayit.RehberId = this.kayit.EncRehberId;
        this.kayit.AracId = this.kayit.EncAracId;
        this.kayit.KisiSayisi = this.kayit.Misafirler.length;

        for (let i = 0; i < this.kayit.Misafirler.length; i++) {
          const element = this.kayit.Misafirler[i];
          element.Uyruk = element.UyrukSelect.value;
        }

        this.kayit.Turlar = [];
        let tarih, arrTarih;
        for (let index = 0; index < this.turlar.length; index++) {
          const element = this.turlar[index];
          if (element.Secili) {
            tarih = this.$formatHelper.formatDate(element.Tarih);
            arrTarih = tarih.split(".");

            this.kayit.Turlar.push({
              TurId: element.EncId,
              TurOpsiyonId: element.OpsiyonId,
              RestoranId: element.RestoranId,
              Tarih: arrTarih[2] + "-" + arrTarih[1] + "-" + arrTarih[0],
              Saat: element.Saat,
              Mekanlar: element.Mekanlar.map(mekan => mekan.Id)
            });
          }
        }

        this.$emit("onComplete", this.kayit);
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.tur-opsiyon-zemin,
.radio label {
  display: block !important;
}

.vue-form-wizard .wizard-nav-pills>li>a .stepTitle {
  color: #333;
  font-weight: bold;
}

.vue-form-wizard .wizard-btn.wizard-footer-first {
  background-color: #e1e1e1 !important;
  border-color: #e1e1e1 !important;
  color: #333 !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-back {
  background-color: #333 !important;
  border-color: #333 !important;
  color: #fff !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-cancel {
  background-color: #ff5370 !important;
  border-color: #ff5370 !important;
  color: #fff !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-next {
  color: #fff !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-finish {
  background-color: #22af47 !important;
  border-color: #22af47 !important;
  color: #fff !important;
}
</style>
